/* @import './stylesheet.css'; */
@import './swiper.css';
@tailwind base;
@tailwind components;

.product-page-expanded-offer .swiper-container {
	padding-bottom: 30px;
	position: initial;
}

.product-page-expanded-offer .swiper-container-horizontal .swiper-scrollbar {
	width: 92%;
	left: 50%;
	transform: translateX(-50%);
}

@tailwind utilities;

@layer base {
	body ::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	body ::-webkit-scrollbar-track {
		border-radius: 6px;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	}

	body ::-webkit-scrollbar-thumb {
		background-color: #c9c9c9;
		border-radius: 6px;
	}

	body {
		@apply font-condensed text-14 text-grey-900;
	}

	p {
		@apply text-14;
	}
}

@layer components {
	.loading {
		position: relative;
		opacity: 0.75;
	}

	#ymDivBar {
		width: 0px !important;
	}

	.loading:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 9998;
		/* background-color: rgba(0, 0, 0, 0.2); */
	}

	.loading:after {
		content: '';
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		z-index: 9999;
		width: 40px;
		height: 40px;
		background-size: 7%;
		background-repeat: no-repeat;
		background-position: center;
		border: 4px solid #3643BA;
		border-left-color: #ffffff;
		border-top-color: #ffffff;
		border-radius: 50%;
		display: inline-block;
		animation: rotate 600ms infinite linear;
	}

	.bluescroll::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 130, 195, 0.2);
		border-radius: 10px;
		background: #3643BA;
	}

	.bluescroll::-webkit-scrollbar {
		width: 6px;
		height: 5px;
	}

	.bluescroll::-webkit-scrollbar-thumb {
		background-color:#3643BA;
		border-radius: 6px;
	}

	@keyframes rotate {
		to {
			transform: rotate(1turn);
		}
	}

	.shake {
		animation: shake 0.8s ease;
	}

	@keyframes shake {
		10%,
		90% {
			transform: translate3d(-1px, 0, 0);
		}

		20%,
		80% {
			transform: translate3d(2px, 0, 0);
		}

		30%,
		50%,
		70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%,
		60% {
			transform: translate3d(4px, 0, 0);
		}
	}

	.overlay {
		@apply fixed inset-0 z-30 bg-black opacity-50;
	}

	.dialog {
		@apply fixed z-40 mx-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2;
	}

	.checkbox {
		@apply w-4 h-4 bg-white border border-blue-400 rounded;
	}

	.checkmark {
		@apply w-full h-full bg-blue-400 border-2 border-white rounded;
	}

	#wizParDiv2 {
		display: none !important;
	}

	.img_zoom {
		@apply relative overflow-hidden rounded-xl;
	}

	.img_zoom .dualtext {
		z-index: 2;
		@apply absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-40;
	}

	.menu-icon {
		width: 30px;
		height: 30px;
		position: relative;
	}

	.videoCenterBtn {
		position: absolute; 
		left: 50%;
		bottom: 3%;
		transform: translate(-50%, -50%);
	}

	@media screen and (max-width: 425px) {
		.menu-icon {
			width: 20px;
			height: 20px;
			position: relative;
		}
	}

	.menu-icon > span {
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #fff;
		-webkit-transition: 0.5s ease;
		top: 50%;
		left: 0;
		transform: translate(0%, -50%);
		transition: 0.5s ease-out;
	}

	.menu-icon > span:nth-child(1) {
		margin-top: -10px;
	}

	.menu-icon > span:nth-child(2) {
		width: 50%;
	}

	.menu-icon > span:nth-child(3) {
		margin-top: 10px;
	}

	.menu-icon.is-open > span:nth-child(1) {
		margin: 0;
		transform: rotate(45deg);
	}

	.menu-icon.is-open > span:nth-child(2) {
		transform: translate(50%);
		opacity: 0;
	}

	.menu-icon.is-open > span:nth-child(3) {
		margin: 0;
		transform: rotate(-45deg);
	}

	.button {
		@apply px-4 py-2 rounded-md rounded-bl-none text-12;
	}

	.deca-checkbox {
		@apply w-4 h-4 border rounded appearance-none border-grey-400;
	}

	.deca-checkbox:checked {
		@apply bg-blue-400 border-transparent;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
	}

	.deca-input {
		@apply w-full px-2 py-2 border-b outline-none border-grey-200 text-14 text-grey-600;
	}

	.collapse:not(.show) {
		display: none;
	}

	.collapsing {
		position: relative;
		height: 0;
		overflow: hidden;
		transition: height 0.35s ease;
	}

	.skeleton {
		background-size: 350% 100%;
		background-image: linear-gradient(120deg, #f4f4f4 35%, #fcfcfd 50%, #f4f4f4 65%) !important;
		animation: skeletonAnimation 1s ease-in-out infinite;
	}

	.skeleton.dark {
		background-image: linear-gradient(120deg, #e6e6e6 35%, #f4f4f4 50%, #e6e6e6 65%) !important;
	}

	@-webkit-keyframes skeletonAnimation {
		0% {
			background-position: 120% 0;
		}

		100% {
			background-position: 0% 0;
		}
	}

	@keyframes skeletonAnimation {
		0% {
			background-position: 120% 0;
		}

		100% {
			background-position: 0% 0;
		}
	}

	.bannerPriceRight::before {
		transform: skew(-30deg);
	}

	.bannerPriceLeft::before {
		transform: skew(10deg);
	}

	.swiperWrapperClass {
		margin-top: 60px;
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}

	.categoryCarouselSwiperWrapperClass {
		display: flex;
	}

	.check:after {
		content: '';
		transition: 0.3s 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
	}

	.check:before {
		display: none !important;
	}

	.storePickupCard.activeCheck .check:after {
		transform: scale(1);
	}

	.giftcardCheck:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		z-index: 1;
		width: 18px;
		height: 18px;
		border-radius: 4px;
		background-color: #fff;
		border: 1px solid #707070;
	}

	.giftcardCheck.active:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		z-index: 2;
		width: 12px;
		height: 12px;
		border-radius: 4px;
		background-color: #424553;
	}

	.dOptImg {
		background: #f8f9f9 0% 0% no-repeat padding-box;
	}

	.deliveryRating {
		opacity: 1 !important;
		position: relative;
	}

	.submitFeedback {
		opacity: 1 !important;
		transform: translateX(-100%);
	}

	.thankYou {
		opacity: 1 !important;
		transform: translateX(-200%);
	}

	.delivery1 {
		opacity: 0;
	}

	.text-shadow1 {
		text-shadow: 3px 2px 1px #333;
	}

	.submitFeedbackInp input:checked ~ .dOptImg {
		border: 1px solid;
		opacity: 1;
	}

	.reviewTab li.active {
		color: #424553;
	}

	.reviewTab li:first-child:after {
		content: '';
		height: 2px;
		width: 30px;
		display: inline-block;
		background: #424553;
		vertical-align: middle;
		margin: 0 20px;
	}

	.raterMsg:after {
		content: '';
		position: absolute;
		top: 8px;
		left: -10px;
		border-top: 10px solid transparent;
		border-right: 10px solid #f4f4f4;
		border-bottom: 10px solid transparent;
		transition: 0.4s ease;
	}

	.raterMsg.active {
		background-color: #feb500;
		color: #fff;
	}

	.raterMsg.active:after {
		border-right: 10px solid #feb500;
	}

	.react-rater-star {
		margin-right: 4px;
		font-size: 24px;
		cursor: pointer;
		color: #c6c6c6;
	}

	.react-rater-star.is-active {
		color: #feb500;
	}

	div[class='react-rater-star is-active'] {
		color: #feb500;
	}

	.reviewPopup.decaModal .decaModalWrapper {
		max-width: 700px;
	}

	.reviewPopup [deca-popup-content] {
		@apply p-10;
	}

	.reviewPopup [deca-popup-body] {
		@apply overflow-hidden !important;
	}

	@media screen and (min-width: 767px) {
		.react-select__option:hover {
			background-color: #daf2fe;
		}

		.categoryCarouselSwiperWrapperClass {
			justify-content: center;
		}

		.closePopup:active {
			background-color: rgba(#fff, 0.2);
		}

		.closePopup:after {
			background-color: #fff;
		}

		.closePopup:before {
			background-color: #fff;
		}

		.rightContentWidth {
			flex: 0 0 40%;
		}
	}

	.nowhitespace {
		text-align: left;
	}

	.plusImg.active img {
		transform: rotate(45deg);
	}

	.productScrollSwiperWrapper {
		padding: 1px 0px;
		margin-top: 60px;
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}

	.subCategorySwiper {
		margin-left: 50px;
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}

	.subCategorySwiper .swiper-button-next-prodScroll {
		position: absolute;
		top: 0px;
		width: 20px;
		background: #fff;
		right: 3px;
		border: 1px solid #424453;
		border-radius: 0 3px 3px 0;
		border-left: none;
		height: 22px;
	}

	.subCategorySwiper ~ .swiper-button-prev {
		left: 0px !important;
	}

	.productCarouselTitleSwiperWrapper {
		display: flex;
		padding-bottom: 15px;
	}

	.productCarouselTitleSwiperWrapperBestSeller {
		display: flex;
		padding: 4px 0px;
		margin-top: 40px;
	}

	.check:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		z-index: 2;
		width: 9px;
		height: 9px;
		background-color: #424553;
		border-radius: 3px;
		transform: scale(0);
		transition: 0.3s 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
	}

	.check:before {
		display: none !important;
	}

	.returnModeCard.activeMode .modeCardBody .check:after {
		transform: scale(1);
	}

	.orderCancelPopup [deca-popup-content] {
		background: #f8f9f9;
	}

	@media screen and (max-width: 768px) {
		.height-webkit {
			height: -webkit-fill-available;
		}

		.orderCancelPopup [deca-popup-wrapper] {
			@apply px-0;
		}

		.orderCancelPopup [deca-popup-content] {
			background: #f8f9f9;
			padding: 15px !important;
			padding-top: 0px !important;
		}

		.orderCancelPopup [deca-popup-body] {
			max-height: calc(90vh - 60px);
		}

		.productCarouselTitleSwiperWrapper {
			padding-bottom: 14px;
		}

		.productCarouselTitleSwiperWrapperBestSeller {
			padding-bottom: 10px;
			margin-top: 0px;
		}

		.btn-close:after,
		.btn-close:before {
			content: '';
			position: absolute;
			top: 0;
			left: 11px;
			width: 2px;
			height: 100%;
			background-color: #8e8e8e;
		}

		.btn-close:before {
			transform: rotate(45deg);
		}

		.btn-close:after {
			transform: rotate(-45deg);
		}
	}

	.categoryListingActive img {
		transform: rotate(45deg);
	}

	@media screen and (max-width: 1024px) {
		.trackReturnStatus [deca-popup-content] {
			background: #f8f9f9 !important;
		}

		.tabContentWrapper {
			transition: transform ease 250ms;
		}

		.active.tabContentWrapper {
			transform: translate3d(-390px, 0, 0);
			visibility: visible;
		}

		.productReturnPopup [deca-popup-wrapper].sm {
			max-width: 100%;
		}

		.productReturnPopup [deca-popup-body] {
			padding-bottom: 60px;
		}
	}

	@media screen and (min-width: 992px) {
		.tabContentWrapper:not(.active) {
			display: none;
		}
	}

	@media screen and (min-width: 1025px) {
		.img_zoom > .dualImage {
			transition: all 0.3s ease;
		}

		.img_zoom:hover > .dualImage {
			transform: scale(1.08);
		}

		.trackReturnStatus [deca-popup-body] {
			max-height: calc(100vh - 145px) !important;
		}
	}

	.myprofileForm input:checked ~ p {
		border: 1px solid;
	}

	.slideButton {
		width: calc(50% - 5px);
	}

	.sizebutton {
		border: 1px solid #e1e1e1;
		display: inline-flex;
		padding: 7px 20px;
		margin-right: 15px;
		font-size: 1.2rem;
		font-weight: 600;
		cursor: pointer;
		transition: 0.2s ease;
		user-select: none;
		white-space: nowrap;
		justify-content: center;
	}

	.sizebutton:hover {
		background: #E1E3F5;
		border-color: #E1E3F5;
		color: #fff;
	}

	@media screen and (max-width: 767px) {
		.reviewPopup [deca-popup-content] {
			padding: 1.5rem;
		}

		.sizebutton {
			display: flex;
		}

		.reviewPopup.decaModal .decaModalWrapper {
			height: 100%;
			overflow-y: scroll;
		}
	}

	.relativeDisableButton {
		opacity: 0.6;
		cursor: not-allowed !important;
		background: linear-gradient(to bottom left, #d9dde178 calc(50% - 1px), #aaa, #d9dde178 calc(50% + 1px));
		color: #00000084;
		border: 1px solid #acacac84 !important;
	}

	.relativeDisableButtonOutOfStock {
		opacity: 0.6;
		cursor: not-allowed !important;
		background: linear-gradient(to bottom right, #3643BA calc(50% - 1px), #aaa, #3643BA calc(50% + 1px));
	}

	.activeSizeButton {
		background: #E1E3F5;
		border-color: #3643BA;
		color: #101010;
	}

	.swiper-button-next-cartReco {
		position: absolute;
		top: 45%;
		width: calc(var(--swiper-navigation-size) / 44 * 27);
		height: var(--swiper-navigation-size);
		margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
		z-index: 10;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--swiper-navigation-color, var(--swiper-theme-color));
		left: auto;
		--swiper-navigation-color: #ffffff;
		background: #fff;
		right: 3px;
		border: 1px solid #424453;
		border-radius: 0 3px 3px 0;
		border-left: none;
	}

	.swiper-button-next-cartReco::after {
		font-family: swiper-icons, sans-serif;
		letter-spacing: 0;
		text-transform: none;
		font-variant: initial;
		content: 'next';
		font-size: 15px !important;
		color: #424453;
	}

	.swiper-button-next-cartReco::before {
		font-family: swiper-icons, sans-serif;
		font-size: var(--swiper-navigation-size);
		letter-spacing: 0;
		text-transform: none;
		font-variant: initial;
		background: #fff;
		content: '';
		position: absolute;
		height: 44px;
		width: 8px;
		transform: skew(10deg);
		left: -4px;
		border: 1px solid #424453;
		border-right: none;
	}
}

.swiper-button-prev-cartReco {
	position: absolute;
	top: 45%;
	width: calc(var(--swiper-navigation-size) / 44 * 27);
	height: var(--swiper-navigation-size);
	margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--swiper-navigation-color, var(--swiper-theme-color));
	left: 10px;
	background: #fff;
	right: 3px;
	border: 1px solid #424453;
	border-radius: 3px 0 0 3px;
	border-right: none;
}

.swiper-button-prev-cartReco::after {
	font-family: swiper-icons, sans-serif;
	letter-spacing: 0;
	text-transform: none;
	font-variant: initial;
	content: 'prev';
	font-size: 15px !important;
	color: #424453;
}

.swiper-button-prev-cartReco::before {
	font-family: swiper-icons, sans-serif;
	font-size: var(--swiper-navigation-size);
	letter-spacing: 0;
	text-transform: none;
	font-variant: initial;
	background: #fff;
	content: '';
	position: absolute;
	height: 44px;
	width: 8px;
	transform: skew(10deg);
	right: -4px;
	border: 1px solid #424453;
	border-left: none;
}

.swiper-button-next-prodScroll {
	position: absolute;
	top: 0px;
	width: 24px;
	background: #fff;
	right: 3px;
	border: 1px solid #949494;
	border-radius: 50%;
	height: 24px;
}



.swiper-button-next-prodScroll::after {
	content: '';
	position: relative;
	display: inline-block;
	top: 0px;
	right: -6px;
	width: 6px;
	height: 6px;
	border-right: 1px solid #3643BA;
	border-top: 1px solid #3643BA;
	transform: rotate(45deg);
}

.swiper-button-prev-prodScroll {
	position: absolute;
	top: 0px;
	width: 24px;
	background: #fff;
	border: 1px solid #949494;
	border-radius: 50%;
	right: 37px;
	left: unset;
	height: 24px;
}



.button-displace {
	top: 45%;
}

.swiper-button-prev-prodScroll::after {
	content: '';
	position: relative;
	display: inline-block;
	right: -9px;
	width: 6px;
	height: 6px;
	border-right: 1px solid #3643BA;
	border-top: 1px solid #3643BA;
	transform: rotate(-135deg);
}

/* css for complete your kit arrow  */
.swiper-button-next-completeKit {
	position: absolute;
	bottom: -9px;
	width: 20px;
	right: 3px;
	height: 22px;
}

.swiper-button-next-completeKit::before {
	content: '';
	position: absolute;
	top: -1px;
	height: 22px;
	width: 8px;
	transform: skew(10deg);
	left: -4px;
}

.swiper-button-next-completeKit::after {
	content: '';
	position: relative;
	display: inline-block;
	top: -2px;
	right: -5px;
	width: 8px;
	height: 8px;
	border-right: 1px solid black;
	border-top: 1px solid black;
	transform: rotate(45deg);
}

.swiper-button-prev-completeKit {
	position: absolute;
	bottom: -9px;
	width: 20px;
	left: unset;
	height: 22px;
}

.swiper-button-prev-completeKit::before {
	content: '';
	position: absolute;
	height: 22px;
	width: 7px;
	transform: skew(10deg);
	right: -2px;
	top: -1px;
	border-top: none;
}

.swiper-button-prev-completeKit::after {
	content: '';
	position: relative;
	display: inline-block;
	top: -2px;
	right: -9px;
	width: 8px;
	height: 8px;
	border-right: 1px solid black;
	border-top: 1px solid black;
	transform: rotate(-135deg);
}

.swiper-container > .swiper-button-disabled {
	opacity: 0.35;
	pointer-events: auto;
}

.table {
	width: 100%;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}

.swiper-container-horizontal > .swiper-scrollbar {
	position: absolute;
	bottom: 0px;
	z-index: 0;
	height: 5px;
	background: rgba(0, 130, 195, 0.2);
}

.swiper-scrollbar .swiper-scrollbar-drag {
	height: 100%;
	width: 100%;
	position: relative;
	background:#3643BA;
	border-radius: 10px;
	left: 0%;
	top: 0;
}

.swiper-scrollbar-cursor-drag {
	cursor: move;
}

/* store orders pagination */
.decaPagination ul {
	@apply flex justify-end;
}

.decaPagination button.paginationLink[aria-label] {
	background: transparent;
}

.decaPagination button.paginationLink[aria-label*='Previous'] img {
	transform: rotate(90deg);
}

.decaPagination button.paginationLink[aria-label*='Next'] img {
	transform: rotate(-90deg);
}

.decaPagination .paginationItem .active {
	background-color: #424453;
	color: #fff;
}

.decaModalWrapper {
	transition: opacity 0.3s ease, transform 0.3s 0.3s ease;
}

/* deca popup close button */
.closePopup:active {
	background-color: rgba(#bbb, 0.2);
}

.closePopup:after {
	content: '';
	position: absolute;
	top: 8px;
	left: 18px;
	width: 2px;
	height: 25px;
	background-color: #bbb;
	transform: rotate(-45deg);
}

.closePopup:before {
	content: '';
	position: absolute;
	top: 8px;
	left: 18px;
	width: 2px;
	height: 25px;
	background-color: #bbb;
	transform: rotate(45deg);
}

.productItemButtons .btnList + .btnList {
	margin-top: 12px;
}

/* deca popup */
.decaModal {
	position: fixed !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	transition: 0.3s ease;
}

.decaModal.open {
	background-color: rgba(55, 61, 64, 0.8);
}

.decaModalWrapper {
	transition: opacity 0.3s ease, transform 0.3s 0.3s ease;
	transform: translateY(20px);
	opacity: 0;
	width: 100%;
}

.decaModal.open .decaModalWrapper {
	transition: 0.3s 0.3s ease;
	transform: translateY(0px);
	opacity: 1;
}

.decaModal .decaModalSize-confirmation {
	max-width: 350px;
}

.decaModal .decaModalSize-xl {
	max-width: 1000px;
}

.decaModal .decaModalSize-lg {
	max-width: 800px;
}

.decaModal .decaModalSize-sm {
	max-width: 600px;
}

.decaModal .decaModalSize-xs {
	max-width: 500px;
}

.decaModal .decaModalSize-confirmation {
	max-width: 350px;
}

.decaModal .decaModalSize-confirmation .decaModalContent {
	background: #f8f9f9 !important;
}

.order-returns-popup.open {
	background-color: rgba(55, 61, 64, 0.8);
}

.order-returns-popup.open .order-returns-wrapper {
	transition: 0.3s 0.3s ease;
	transform: translateY(0px);
	opacity: 1;
}

.order-returns-popup .order-returns-wrapper {
	transition: opacity 0.3s ease, transform 0.3s 0.3s ease;
	transform: translateY(20px);
	opacity: 0;
}

.back-button-icon.active {
	@apply bg-grey-100;
}

.order-status-badge .tooltip {
	opacity: 1;
	z-index: 999999;
	margin-left: 5px;
}

.order-status-badge.order-cancelled {
	background-color: #fec9cb;
	padding: 7px 10px;
	border-radius: 8px;
	display: inline-block;
	margin-bottom: 10px;
}

.order-status-badge .tooltip-inner {
	max-width: 380px;
	min-width: 210px;
	padding: 10px 15px;
	text-align: left;
	background-color: #373d40;
	border-radius: 8px;
	position: relative;
	right: 90px;
	top: -9px;
	font-size: 12px;
	color: #fff;
}

.order-status-badge .tooltip .arrow {
	content: '';
	width: 0px;
	height: 0px;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #373d40;
	bottom: 0;
	transform: translate(-50%, 0%);
}

.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
	bottom: 0;
}

.tooltip .arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}

.shipping-address .active {
	border-bottom-color: #3643BA;
	color: #373d40;
	font-weight: 600;
}

.close-popup:active {
	background-color: rgba(#fff, 0.2);
}

.close-popup:after {
	content: '';
	position: absolute;
	top: 8px;
	left: 18px;
	width: 2px;
	height: 25px;
	background-color: #fff;
	transform: rotate(-45deg);
}

.close-popup:before {
	content: '';
	position: absolute;
	top: 8px;
	left: 18px;
	width: 2px;
	height: 25px;
	background-color: #fff;
	transform: rotate(45deg);
}

.summery-detail-block p:last-child {
	margin-bottom: 0;
}

.productReturnPopup [deca-popup-wrapper].sm {
	max-width: 500px;
}

.productReturnPopup [deca-popup-body] {
	background: #fff;
	padding: 20px;
}

@media screen and (max-width: 767px) {
	.decaModal.confirmationPopup .decaModalWrapper {
		margin: 0 10px;
	}

	.swiper-button-next-completeKit::after {
		right: -12px;
	}

	.swiper-button-prev-completeKit::after {
		right: -4px;
	}

	.decaModal.confirmationPopup .closePopup:before,
	.decaModal.confirmationPopup .closePopup:after {
		background-color: #c6c6c6;
	}

	.decaModal.mobileAutoHeight {
		align-items: flex-end;
	}

	.decaModal.confirmationPopup .decaModalWrapper {
		margin: 0 10px;
	}

	.decaModal.decaModelTitle .decaModalWrapper {
		height: 100%;
	}

	.decaModal.confirmationPopup .decaModalWrapper {
		margin: 0 10px;
	}

	.decaModal.decaModelTitle .decaModalWrapper {
		height: 100%;
	}

	.decaModal.mobileAutoHeight .decaModalWrapper {
		height: auto !important;
	}

	.decaModalWrapper.decaModalSize-confirmation {
		width: auto;
		height: auto;
	}

	.decaModal.decaModelTitle .decaModalWrapper.decaModalSize-confirmation {
		width: auto;
		height: auto;
	}

	.order-status-badge .tooltip {
		z-index: 9999999;
	}

	.order-status-badge .tooltip-inner {
		right: 10px;
	}

	.order-cancelled-product .tooltip {
		transform: translate3d(82px, 52px, 0px) !important;
	}

	.order-status-badge .arrow:before {
		right: 3px;
	}
}

.product-collapse-heading.open img {
	transform: scaleY(-1);
}

.rheostat.rheostat_1 {
	text-align: center;
	position: relative;
}

.rheostat.rheostat_1 > .DefaultBackground {
	background-color: #f4f4f4;
	height: 7px;
	border: none;
}

.rheostat.rheostat_1 > .DefaultProgressBar_progressBar {
	height: 7px;
	background-color: #3643BA;
	position: absolute;
	top: 0;
}

.rheostat.rheostat_1 > .handleContainer {
	height: 15px;
	top: -6px;
	left: -2px;
	bottom: 4px;
	width: 100%;
	position: absolute;
}

.rheostat.rheostat_1 > .handleContainer > .DefaultHandle_handle {
	background-color: #3643BA;
	border-color: #3643BA;
	border-radius: 2px;
	height: 20px;
	width: 20px;
	box-shadow: none;
	margin-left: 0;
	text-shadow: none;
	border-width: 1px;
	border-style: solid;
}

.rheostat.rheostat_1 > .handleContainer > .DefaultHandle_handle:before,
:after {
	content: none;
}

.rheostat.rheostat_1 > .handleContainer > .DefaultHandle_handle:last-child {
	margin-left: -17px !important;
}

/* Algolia Search */

/* Algolia Sidebar */
.algolia_search .ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
	color: #424453;
	border: 1px solid #ddd;
	background: transparent;
}

.algolia_search .right-panel .ais-InfiniteHits-list {
	margin: 0 !important;
	display: flex;
	flex-wrap: wrap;
}

.algolia_search .right-panel .ais-InfiniteHits-item {
	padding: 0 !important;
	box-shadow: none !important;
	margin-top: 0;
	margin-bottom: 1.5rem;
	width: 50%;
	list-style-type: none;
	border: 0.25px solid #d9dde1;
}

/* .algolia_search .right-panel .ais-InfiniteHits-item:nth-last-child(-n + 2) {
	border-bottom: 0.5px solid #d9dde1;
} */

@media screen(sm) {
	.algolia_search .right-panel .ais-InfiniteHits-item {
		@apply w-1/3 border-0;
	}

	.algolia_search .right-panel .ais-InfiniteHits-item:nth-last-child(-n + 2) {
		border: none;
	}
}

@media screen(md) {
	.algolia_search .right-panel .ais-InfiniteHits-list {
		padding: 0 22px;
	}

	.algolia_search .right-panel .ais-InfiniteHits-item {
		@apply w-1/3;
	}
}

@media screen(lg) {
	.algolia_search .right-panel .ais-InfiniteHits-list {
		@apply pr-0;
	}

	.algolia_search .right-panel .ais-InfiniteHits-item {
		@apply w-1/4;
	}
}

@media screen(2xl) {
	.algolia_search .right-panel .ais-InfiniteHits-item {
		@apply w-1/5;
	}
}

.algolia_search .right-panel .ais-CurrentRefinements-list {
	padding: 0;
	list-style-type: none;
}

.algolia_search .right-panel .ais-ClearRefinements-button {
	background: transparent;
	color: #ec6607;
	font-size: 1rem;
	white-space: nowrap;
	border: none;
}

.algolia_search .right-panel .ais-ClearRefinements-button:hover {
	background: transparent;
	color: #ec6607;
}

.algolia_search .ais-Pagination {
	margin: 1.5rem 0 0;
	padding-bottom: 2rem;
}

.algolia_search .ais-Pagination .ais-Pagination-list {
	justify-content: center;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
}

.algolia_search .ais-Pagination .ais-Pagination-list .ais-Pagination-item {
	list-style-type: none;
	margin-right: 10px;
}

.algolia_search .ais-Pagination .ais-Pagination-link {
	border: none !important;
	color: #424453 !important;
	border-radius: 3px !important;
	padding: 5px 12px;
	background-color: #f4f4f4;
}

.algolia_search .ais-Pagination .ais-Pagination-link:hover {
	background: #f7f7f7;
	color: #424453;
}

.algolia_search .ais-Pagination .ais-ToggleRefinement-count {
	border: none !important;
	color: #424453 !important;
	border-radius: 3px !important;
	padding: 5px 12px;
	background-color: #f4f4f4;
}

.algolia_search .ais-Pagination-item--selected .ais-Pagination-link {
	color: #fff !important;
	background-color: #424453 !important;
	border-color: #424453 !important;
	border-radius: 3px;
}

.algolia_search .ais-Pagination-item--nextPage .ais-Pagination-link,
.algolia_search .ais-Pagination-item--previousPage .ais-Pagination-link {
	background: none;
	font-size: 2rem;
	padding: 0 15px;
	line-height: 1;
	border: none !important;
}

.algolia_search .right_range {
	float: right;
}

.algolia_search .price_filters .ais-Panel-header {
	margin-bottom: 17px !important;
}

.algolia_search .ais-Panel {
	margin-bottom: 1rem;
	float: none;
	clear: both;
}

.algolia_search .ais-Panel-header {
	margin-bottom: 0.5rem;
	padding-bottom: 0 !important;
	font-size: 0.9375rem !important;
	border: none !important;
	color: #424453;
	font-weight: bold;
}

.algolia_search .ais-RefinementList-showMore {
	background: transparent;
	color: #3643BA;
	padding: 0;
	font-size: 0.9375rem;
}

.algolia_search .ais-RefinementList-showMore:hover {
	background: transparent;
	outline: none;
}

.algolia_search .ais-RefinementList-showMore:focus {
	background: transparent;
	outline: none;
}

.algolia_search .ais-RefinementListLabelText {
	font-size: 0.8125rem !important;
}

/* Custom Checkbox */
.aisRefinementListCheckbox {
	position: absolute;
	/* take it out of document flow */
	opacity: 0;
	/* hide it */
}

.aisRefinementListCheckbox + .aisRefinementListLabelText {
	position: relative;
	cursor: pointer;
	padding: 0;
}

/* Box */
.aisRefinementListCheckbox + .aisRefinementListLabelText:before {
	content: '';
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 15px;
	height: 15px;
	background: white;
	border: 1px solid #424453;
	border-radius: 2px;
}

/* Box checked */
.aisRefinementListCheckbox:checked + .aisRefinementListLabelText:before {
	background: #3643BA;
	border-color:#424453;
}

/* Disabled state label */
.aisRefinementListCheckbox:disabled + .aisRefinementListLabelText {
	color: #b8b8b8;
	cursor: auto;
}

/* Disabled box */
.aisRefinementListCheckbox:disabled + .aisRefinementListLabelText:before {
	box-shadow: none;
	background: #ddd;
}

/* Checkmark. Could be replaced with an image */
.aisRefinementListCheckbox:checked + .aisRefinementListLabelText:after {
	content: '';
	position: absolute;
	left: 2px;
	top: 8px;
	background: white;
	width: 2px;
	height: 2px;
	box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
	transform: rotate(45deg);
}

@media (min-width: 481px) and (max-width: 767px) {
	.ais-InfiniteHits-item,
	.ais-InfiniteResults-item,
	.ais-Results-item {
		width: calc(52% - 1rem) !important;
	}
}

@media screen and (max-width: 1024px) {
	.algolia_search .ais-InfiniteHits-item,
	.ais-InfiniteHits-item,
	.ais-InfiniteResults-item,
	.ais-Results-item {
		width: 100%;
		margin-left: 0 !important;
		margin-bottom: 0px !important;
		transition: 0.4s ease-in;
	}
}

@media (max-width: 400px) {
	.react-toast-notifications__toast {
		width: 300px;
	}
}

.highlight {
	background-color: #e7e7e7 !important;
}

[product-offers].highlight {
	background-color: transparent !important;
}

[product-offers].highlight > div {
	background-color: #e6e8e9;
}

[product-offers].highlight > div [badge-title]:after,
[product-offers].highlight > div [badge-title]:before {
	background-color: #e6e8e9 !important;
}

/* Sticker Mixins */
.stickers,
.limitedstock,
.clearance,
.price_drop,
.lowest_price,
.best_value,
.new_sticker,
.innovation,
.onlyonline,
.second-life-product-sticker,
.ecodesign,
.toprated,
.todaysdeal,
.limitedstock,
.sticker_bestsellers {
	padding: 5px 7px 4px 10px;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	margin: 0;
}

.stickers:before,
.limitedstock:before,
.clearance:before,
.price_drop:before,
.lowest_price:before,
.onlyonline:before,
.second-life-product-sticker:before,
.ecodesign:before,
.toprated:before,
.todaysdeal:before,
.limitedstock:before,
.sticker_bestsellers:before,
.best_value:before,
.new_sticker:before,
.innovation:before {
	content: '';
	width: 10px;
	height: 100%;
	position: absolute;
	transform: skew(10deg);
	right: -5px;
	top: 0;
}

.price_tag {
	background: #ffea29;
	border: none;
	font-size: 1rem;
	padding: 6px 15px 6px 15px;
	font-weight: 600;
	border-radius: 4px 0 0 4px;
	position: relative;
	margin-right: 20px;
	display: table-cell;
	color: #0f384d;
}

.price_tag:after {
	background: #ffea29;
	width: 13px;
	height: 100%;
	position: absolute;
	transform: skew(10deg);
	content: '';
	right: -5px;
	top: 0px;
	z-index: 1;
}

.price_tag:hover {
	background: #ffea29;
	color: #0f384d;
}

/* // Sticker Mixins end */
/* // Price button Mixins */

/* // Stickers Css Start */
.clearance {
	background: #fe2d0e;
	color: #fff;
}

.clearance:after {
	content: '';
	background: #ff3a0b;
	width: 10px;
	height: 100%;
	position: absolute;
	transform: skew(10deg);
	right: -5px;
	top: 0;
}

.price_drop {
	background: #3643BA;
	padding-right: 10px;
}

.price_drop:before {
	content: '';
	background: #3643BA;
	width: 10px;
	height: 100%;
	position: absolute;
	transform: skew(10deg);
	right: -5px;
	top: 0;
}

.lowest_price {
	border-radius: 2px 0 0 0px;
	color: #fff;
	background: #3643BA;
}

.lowest_price:before {
	background: #3643BA;
}

.best_value {
	background: #ffea29;
	color: #000;
	display: table;
	padding-right: 21px;
}

.best_value:before {
	background: #ffea29;
	right: -8px;
}

.new_sticker {
	background: #03c289;
	color: #fff;
}

.new_sticker:before {
	background: #03c289;
}

.innovation {
	background: #1f2d34;
	color: #fff;
}

.innovation:before {
	background: #1f2d34;
}

.onlyonline {
	background: #8126a3;
	color: #fff;
}

.onlyonline:before {
	background: #8126a3;
}

.second-life-product-sticker {
	background: #50825c;
	color: #fff;
}

.second-life-product-sticker:before {
	background: #50825c;
}

.ecodesign {
	background: #50825c;
	color: #fff;
}

.ecodesign:before {
	background: #50825c;
}

.toprated {
	background: #3643BA;
	color: #fff;
}

.toprated:before {
	background: #3643BA;
}

.todaysdeal {
	background: #3643BA;
	color: #fff;
}

.todaysdeal:before {
	background: #3643BA;
}

.limitedstock {
	background: #3643BA;
	color: #fff;
}

.limitedstock:before {
	background: #3643BA;
}

.sticker_bestsellers {
	background: #3643BA;
	color: #fff;
}

.sticker_bestsellers:before {
	background: #3643BA;
}

@media screen and (max-width: 767px) {
	.lowest_price {
		padding: 3px 7px 3px 10px;
		line-height: 14px;
	}

	.best_value {
		padding: 3px 22px 3px 10px;
		line-height: 14px;
	}

	.price_drop {
		line-height: 15px;
	}

	.price_drop::before {
		height: 16px;
	}

	.clearance {
		line-height: 14px;
	}
}

.popover {
	top: 0;
	left: 0;
	z-index: 20;
	max-width: 276px;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
		Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	/* border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem; */
}

.popover .arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
	margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
	position: absolute;
	display: block;
	content: '';
	border-color: transparent;
	border-style: solid;
	top: 1px;
	border-width: 0 0.5rem 0.5rem;
	border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'] > .arrow,
.bs-popover-bottom > .arrow {
	top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='top'] > .arrow:before,
.bs-popover-top > .arrow:before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	top: 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='top'] > .arrow:after,
.bs-popover-top > .arrow:after {
	bottom: 1px;
	top: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.css-1okebmr-indicatorSeparator {
	display: none;
}

.react-select [class*='-indicatorContainer'] svg {
	fill: #424453;
}

.css-1okebmr-indicatorSeparator {
	display: none;
}

.react-select [class*='-indicatorContainer'] svg {
	fill: #424453;
}

.netbanking_banks [class*='-control'] {
	outline: none !important;
	box-shadow: none !important;
}

.react-select__control {
	min-height: 44px;
	background-color: #f4f4f4;
	border-color: #c6c6c6 !important;
	box-shadow: none !important;
}

/* Store information Css */
.StrSectionTitle {
	font-family: 'Roboto,sans-serif';
	font-style: normal;
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	color: #007dbc;
	padding: 20px 0;
	text-align: center;
}

.all-store-blk {
	background: #ffffff;
	box-shadow: 0px 6px 6px rgb(0 83 125 / 10%);
	border-radius: 8px;
	text-align: left;
	padding: 10px 20px;
	font-family: 'Roboto,sans-serif';
}

.StoreNumber {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #001018;
	margin: 0;
}

.StoreStateName {
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	color: #007dbc;
	margin: 0;
}

.strCard {
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}


.size-select-container {
	display: flex;
	flex-wrap: wrap;
}

.paymentSummaryTab::before {
	content: '';
	position: absolute;
	width: 2px;
	height: 110%;
	background-color: #eff1f3;
	margin-left: 18px;
	margin-top: 30px;
}

@media screen and (max-width: 640px) {
	.paymentSummaryTab::before {
		margin-left: 10px;
	}
}

@media screen and (max-width: 640px) {
	[data-tippy-root] {
		visibility: hidden !important;
	}

	.size-select-container {
		flex-wrap: nowrap;
		overflow: auto;
	}
}

@media screen and (min-width: 720px) {
	.size-select-notify-popup-container {
		position: relative;
	}
}

.prompt-popup {
	position: static !important;
}

@keyframes progress-bar-stripes {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 1.5rem 0;
	}
}

.progress-bar {
	overflow: hidden;
	border-radius: 8px;
	height: 0.7rem;
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 0.5s linear infinite;
	background-image: linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.5) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.5) 50%,
		rgba(255, 255, 255, 0.5) 75%,
		transparent 75%,
		transparent
	);
	background-size: 1.5rem 1.5rem;
	background-color: #d3d3d3;
	transition: width 0.6s ease;
}

.filter-scrollbar::-webkit-scrollbar {
	width: 8px;
	background-color: transparent;
}

.filter-scrollbar::-webkit-scrollbar-thumb {
	background-color: #3643BA;
}

.filter-scrollbar::-webkit-scrollbar-track {
	background-color: white;
}

.recent-search-scrollbar::-webkit-scrollbar {
	width: 5px;
	height: 4px;
	background-color: transparent;
}

.recent-search-scrollbar::-webkit-scrollbar-thumb {
	background-color: #3643BA;
}

.recent-search-scrollbar::-webkit-scrollbar-track {
	background-color: white;
}

.decaModalSize-disableCloseIcon > div > button[id='deca-popup-close-icon'] {
	display: none;
}

::-webkit-search-cancel-button {
	color: #3643BA;
	letter-spacing: 1px;
	font-size: 1rem;
	height: 10px;
	width: 10px;
	display: inline-block;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMjMgNS41ODU5OUwxMS45NTAyIDAuNjM1OTg2TDEzLjM2NDIgMi4wNDk5OUw4LjQxNDIzIDYuOTk5OTlMMTMuMzY0MiAxMS45NUwxMS45NTAyIDEzLjM2NEw3LjAwMDIzIDguNDEzOTlMMi4wNTAyMyAxMy4zNjRMMC42MzYyMyAxMS45NUw1LjU4NjIzIDYuOTk5OTlMMC42MzYyMyAyLjA0OTk5TDIuMDUwMjMgMC42MzU5ODZMNy4wMDAyMyA1LjU4NTk5WiIgZmlsbD0iIzA0MmU4YSIvPgo8L3N2Zz4K);
	background-repeat: no-repeat;
	background-size: 10px;
	-webkit-appearance: none;
}



.no-scrollbar::-webkit-scrollbar-thumb {
	visibility: hidden;
}

.no-scrollbar::-webkit-scrollbar-track {
	visibility: hidden;
}

.line-clamp-two-line {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.line-clamp-four-line {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.target-select svg {
	width: 13px;
	height: 15px;
}

#wallet-feature-div::-webkit-scrollbar-thumb {
  background-color: #3643BA;
}

#wallet-feature-div::-webkit-scrollbar {
  width: 4px !important;
}

.slide-right {
  animation: slider 600ms ease-out;
	
}

@keyframes slider {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

.swiper-custom-prev-button.swiper-button-disabled,
.swiper-custom-next-button.swiper-button-disabled {
	cursor: not-allowed;
	opacity: 0.7;
}
.swiper-custom-prev-button,
.swiper-custom-next-button {
	cursor: pointer;
	width: 26px;
	height: 26px;
	border: 1px solid #949494;
	border-radius: 50%;
}
.swiper-custom-prev-button{
	padding: 8px 10px;
	
}
.swiper-custom-next-button{
	padding: 8px 6px;

}
.swiper-custom-prev-button:after {
	content: '';
	display: block;
	width: 8px;
	height: 8px;
	border-right: 1px solid #949494;
	border-top: 1px solid #949494;
	transform: rotate(-135deg);
}
.swiper-custom-next-button:after {
	content: '';
	display: block;
	width: 8px;
	height: 8px;
	border-left: 1px solid #949494;
	border-top: 1px solid #949494;
	transform: rotate(135deg);
}
.product-card-thumb .swiper-slide-thumb-active {
	border: 1px solid #3643BA;
}

.product-page-swiper {
	width: 100%;
}

.product-page-swiper .swiper-pagination-bullet-active-main {
	background-color: rgb(54 67 186 / var(--tw-border-opacity));
}

.category-banner-container .swiper-pagination-bullet-active-main {
	background-color: var(--bullet-color,"#3643BA")
}

.category-banner-container .swiper-pagination-bullet-active-next,
.category-banner-container .swiper-pagination-bullet-active-prev,
.category-banner-container .swiper-pagination-bullet-active-prev-prev,
.category-banner-container .swiper-pagination-bullet-active-next-next
 {
	background-color: var(--bullet-color-inactive,"#a19d9d")
}


.category-banner-container .swiper-pagination-bullet-active-main {
	background-color: var(--bullet-color,"#3643BA")
}

.category-banner-container .swiper-pagination-bullet-active-next,
.category-banner-container .swiper-pagination-bullet-active-prev,
.category-banner-container .swiper-pagination-bullet-active-prev-prev,
.category-banner-container .swiper-pagination-bullet-active-next-next
 {
	background-color: var(--bullet-color-inactive,"#a19d9d")
}


/* Order Cancellation Css */
.orderCancelPopup {
	&.sm {
		max-width: 500px;
	}

	.bgWhite {
		background: #fff;
		padding: 20px;
	}
}

/* Checkout Cart & Address Change Order Cancellation Css */

/* Hide scrollbar for Chrome, Safari and Opera */
.addressScrollBar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.addressScrollBar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.upperShadow {
	width: 100%;
	height: 1px;
	box-shadow:
		3px 3px 3px 3px #0001,
		2px 2px 2px 2px #0001,
		1px 1px 1px 1px #0001;
}

.lowerShadow {
	width: 100%;
	height: 1px;
	box-shadow:
		3px 3px 3px 3px #0001,
		2px 2px 2px 2px #0001,
		1px 1px 1px 1px #0001;
	transform: rotateX(180deg);
}


.recent-search-chip-animate{ 
  position: relative;
  animation: shake-chips 300ms;
}

@keyframes shake-chips {
	0%,50% {
		transform: rotate(5deg);
	}
	12.5%,37.5%,62.5%,100%{
		transform: rotate(0deg);
	}
	25%,75%{
		transform: rotate(-5deg);
}
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

.aisRefinementListCheckbox:checked+.aisRefinementListLabelText::after{
   top: 9px;
}

.custom .aisRefinementListCheckbox:checked+.aisRefinementListLabelText::after{
	top: 6px;
}

.best-seller-swiper-floor > .swiper-slide {
	width: 50%;
	margin-right: 10px;
}
.best-seller-swiper-floor .product-card-thumb {
	max-height: 45px;
}


.recent-search-chip-animate{ 
	position: relative;
	animation: shake-chips 300ms;
  }
  
  @keyframes shake-chips {
	  0%,50% {
		  transform: rotate(5deg);
	  }
	  12.5%,37.5%,62.5%,100%{
		  transform: rotate(0deg);
	  }
	  25%,75%{
		  transform: rotate(-5deg);
  }
}

@media screen and (min-width: 767px){
	.aisRefinementListCheckbox:checked+.aisRefinementListLabelText::after{
		top: 8px;
	}
	.custom .aisRefinementListCheckbox:checked+.aisRefinementListLabelText::after{
		top: 6px;
	}
	.best-seller-swiper-floor > .swiper-slide {
		width: 33.33%;
		margin-right: 0px;
	}
}
@media screen and (min-width: 1024px){
	.best-seller-swiper-floor > .swiper-slide {
		width: 25%;
		margin-right: 30px;
	}
}
@media screen and (min-width: 1440px){
	.best-seller-swiper-floor > .swiper-slide {
		width: 20%;
	}
}
.custom-autofill:-webkit-autofill,
.custom-autofill:-webkit-autofill:hover,
.custom-autofill:-webkit-autofill:focus,
.custom-autofill:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important; 
}
