/* @import 'swiper/css/swiper.min.css'; */
@import '../node_modules/swiper/swiper-bundle.min.css';

.swiper-container {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	/* Fix of Webkit flickering */
	z-index: 1;
}
.swiper-slide {
   height: auto;
}

.swiper-pagination {
   position: absolute;
   text-align: center;
   transition: 300ms opacity;
   transform: translate3d(0, 0, 0);
   z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
   opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
   bottom: 10px;
   left: 0;
   width: 100%;
}

.swiper-pagination-fraction .swiper-pagination-bullet-active,
.swiper-pagination-custom .swiper-pagination-bullet-active,
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active:not(.video-slide) {
   transform: scale(1);
   background-color: var(--bullet-color, #3643BA);
}

.swiper-pagination-bullet-active.video-slide {
   background-color: white;
}

.video-slide>.video-outline {
   display: block;
}

.swiper-pagination-bullet-active.video-slide>.video-outline {
   display: none;
}

.video-slide>.video-solid {
   display: none;
}

.swiper-pagination-bullet-active.video-slide>.video-solid {
   display: block;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
   overflow: hidden;
   font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
   transform: scale(1);
   position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
   transform: scale(2);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
   transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
   transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
   transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
   transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
   transform: scale(0.33);
}

.swiper-pagination-bullet {
   width: 8px;
   height: 8px;
   display: inline-block;
   border-radius: 100%;
   background: #000;
   opacity: var(--bullet-inactive-opacity,0.2);
}

.swiper-pagination-clickable .swiper-pagination-bullet {
   cursor: pointer;
}

.swiper-pagination-bullet-active {
   opacity: 1;
   background: #3643BA;
}

.swiper-container-vertical>.swiper-pagination-bullets {
   right: 10px;
   top: 50%;
   transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
   margin: 6px 0;
   display: block;
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
   top: 50%;
   transform: translateY(-50%);
   width: 8px;
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
   display: inline-block;
   transition: 200ms transform, 200ms top;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
   margin: 0 4px;
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
   left: 50%;
   transform: translateX(-50%);
   white-space: nowrap;
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
   transition: 200ms transform, 200ms left;
}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
   transition: 200ms transform, 200ms right;
}

.swiper-pagination-lock {
   display: none;
}

.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-prev
{
   background-color: var(--bullet-color-inactive, #424453);
}

.swiper-button-prev {
   @apply hidden md:flex;
   background: #fff;
   border: 1px solid #949494;
   border-radius: 50%;
   width:42px;
}
.swiper-button-prev:before {
  
}

.swiper-button-prev:after {
   font-size: 10px !important;
   color: #3643BA;
   font-weight:800;
}

.swiper-button-next {
   @apply hidden md:flex;
   background: #fff;
   border: 1px solid #949494;
   border-radius: 50%;
   width:42px;
}
.swiper-button-next:before {
  
}

.swiper-button-next:after {
   font-size: 10px !important;
   color: #3643BA;
   font-weight:800;
}